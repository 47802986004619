@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes gradientAnimation {
    0% { background-position: 0% 0%; }
    50% { background-position: 100% 100%; }
    100% { background-position: 0% 0%; }
}

.bg-instagram-gradient {
    background: linear-gradient(135deg, #f58529, #dd2a7b, #8134af, #515bd4);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}